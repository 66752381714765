.calculator {
   padding: 150px 0;

   .content-cal {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      text-align: center;

      .sub-title {
         letter-spacing: 0.1em;
         @include font-weight(light);
         color: $yellow;
         font-size: 1rem;
         overflow: hidden;
         position: relative;

         .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include dark-color-bg(medium);
            z-index: 1;
         }
      }

      .title {
         font-size: 3rem;
         @include font-weight(bold);
         margin-top: 0.9rem;

         .hide {
            overflow: hidden;
         }

         span {
            color: $yellow;
         }
      }

      .para {
         color: #FFF;
         text-align: center;
         font-family: Roboto;
         font-size: 18px;
         font-style: normal;
         font-weight: 400;
         line-height: normal;
         opacity: 0.9;
      }

      .normal-btn {
         width: fit-content;
      }
   }
}

.dropdown-wrapper {
   position: absolute;
   top: 20px;
   right: 20px;
   z-index: 1000;
   @include dark-color-bg(light);
}

.currency-dropdown {
   padding: 5px;
   font-size: 16px;
   border: 1px solid #ccc;
   border-radius: 4px;
   @include dark-color-bg(light);
   color: white;
}

.text-3xl {
   color: #fff;
   font-family: Roboto;
   font-size: 16px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
}

.flex-container {
   display: flex;
   flex-direction: column;

   @media (min-width: 768px) {
      flex-direction: row;
   }
}

.form-container {
   display: flex;
   padding: 48px var(--Paragraph-Letter-Spacing, 0px);
   flex-direction: column;
   align-items: flex-start;
   gap: var(--Paragraph-Line-Height, 24px);
   flex: 1;
}

.hidden1{
   display: flex;
}

.text-xl {
   font-size: 1.25rem;
   font-weight: 600;
}

.summary-container {
   display: flex;
   padding: var(--Paragraph-Space, 16px);
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 12px;
   align-self: stretch;
   background: #333;
}

.summary-row {
   display: flex;
   align-items: flex-start;
   gap: 4px;
   align-self: stretch;
}

.dashed {
   // border-bottom-style: dashed;
   // border-bottom-style: dotted;
   border-bottom: 1px dashed;
   // background: rgba(255, 255, 255, 0.20);
   border-color: rgba(255, 255, 255, 0.2);
}

.form {
   &-container {
      & > * + * {
         margin-top: 0.5rem;
      }
   }

   &-question {
      & > * + * {
         margin-top: 0.5rem;
      }
   }

   &-options {
      display: flex;
      gap: 1rem;
   }

   &-option {
      display: flex;
      gap: 0.5rem;
      @include dark-color-bg(light);
      border: 2px solid transparent;
      padding: 0.5rem;
      border-radius: 0.375rem;

      &:focus-within {
         border-color: $yellow;
      }

      input[type="radio"] {
         margin-left: 0.5rem;
         color: yellow;

         &:focus {
            outline: none;
         }

         &:checked {
            background-color: $yellow;
         }
      }

      label {
         margin-right: 0.5rem;
         color: white;
      }
   }
}

.type-wrapper {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 8px;
   align-self: stretch;
}

.web-type {
   width: 100%;
   padding: 10px;
   background: inherit;
   color: #fff;
   border: 1px;
   // border-radius: 4px;
   appearance: none;
   -webkit-appearance: none;
   -moz-appearance: none;
}

.web-type option {
   @include dark-color-bg(light);
}

.extra-options {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 8px;
   align-self: stretch;
}

.optionLabel {
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex: 1;
}

.makeFlex {
   display: flex;
   margin-top: 10px;
   width: 100%;
}

.gap-x {
   margin-top: -3px;
   margin-left: 10px;
}

.main {
   color: #fff;
   font-family: Roboto;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   opacity: 0.8;
}

.sub {
   color: #ffd600;
   font-family: Roboto;
   font-size: 14px;
   align-self: stretch;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
}
.content1{
   display: none;
}

.go-back{
   display: none;
}

.hidden{
   display: none !important;
}


.place-order{
   display: none;
}

.summary {
   display: flex;
   padding: 48px var(--Paragraph-Letter-Spacing, 0px);
   flex-direction: column;
   align-items: flex-start;
   gap: var(--Paragraph-Space, 16px);
   flex: 1;
   position: sticky;
   top: 0;
}

.summary-mobile {
   display: none;
   padding: 48px var(--Paragraph-Letter-Spacing, 0px);
   flex-direction: column;
   align-items: flex-start;
   gap: var(--Paragraph-Space, 16px);
   flex: 1;
   position: sticky;
   top: 0;
}

.total {
   margin-top: 20px h2 {
      font-size: 20px;
   }
}

.black-background {
   background: #000;
   color: white;
   border: none;
   left: 0;
}

.queLabel {
   color: #fff;
   text-align: center;
   font-family: Roboto;
   font-size: 16px;
   font-style: normal;
   line-height: normal;
   text-align: left;
   white-space: nowrap;

   span {
      color: #ffd600;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
   }
}

.calculatorWrapper {
   padding-top: 100px;
}
.calWrap{
   padding-top: 100px;
}
.cal {
   display: flex;
   width: inherit;
   padding: var(--Paragraph-Letter-Spacing, 0px) 48px;
   justify-content: center;
   align-items: flex-start;
   gap: 2rem;
   background: #222;

   

}

.heading1 {
   display: flex;
   padding: var(--Paragraph-Letter-Spacing, 0px) 7px;
   align-items: center;
   gap: var(--Radius-10, 10px);
   align-self: stretch;
}

.name-div {
   display: flex;
   padding: 6px 8px;
   justify-content: center;
   align-items: flex-start;
   gap: var(--Radius-10, 10px);
   border-radius: 5px;
   background: #222;
   width: 296px;

   h3 {
      flex: 1;
      color: #fff;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
   }
}

.summary-item {
   display: flex;
   width: 296px;
   padding: 6px 8px;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   gap: 4px;
}

.cost-div {
   display: flex;
   padding: 6px 8px;
   justify-content: center;
   align-items: flex-start;
   gap: var(--Radius-10, 10px);
   border-radius: 5px;
   background: #222;
   width: 116px;

   h3 {
      flex: 1;
      width: 100px;
      color: #fff;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
   }
}

.cost {
   display: flex;
   padding: 6px 8px;
   align-items: flex-start;
   gap: var(--Radius-10, 10px);
   width: 116px;

   .total-cost {
      color: #ffd600;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
   }

   .dash {
      width: 100px;
      color: #fff;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
   }

   p {
      color: #fff;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
   }
}

.cost1 .total-label {
   display: none;
 }

.cost1{
   display: none;
   padding: 6px 8px;
   align-items: flex-start;
   gap: var(--Radius-10, 10px);
   width: 116px;

   .total-cost {
      color: #ffd600;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
   }

   .dash {
      width: 100px;
      color: #fff;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
   }

   p {
      color: #fff;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
   }
}

.test {
   display: flex;
   padding: 48px var(--Paragraph-Letter-Spacing, 0px);
   flex-direction: column;
   align-items: flex-start;
   gap: var(--Paragraph-Line-Height, 24px);
   flex: 1;
}

.dropdown-cursor {
   width: var(--Paragraph-Space, 16px);
   height: var(--Paragraph-Space, 16px);
}

.custom-select {
   display: flex;
   height: var(--Radius-50, 50px);
   padding: 12px var(--Radius-20, 20px);
   justify-content: space-between;
   align-items: center;
   align-self: stretch;
   border-radius: 5px;
   border: 1px solid rgba(180, 180, 180, 0.3);
}

.dropdown {
   width: 30rem;
   position: relative;
}
.dropdown:hover .dropdown-list {
   opacity: 1;
   visibility: visible;
}
.dropdown-select {
   padding: 1.5rem;
   border-radius: 4px;
   background-color: white;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   font-size: 1.6rem;
   cursor: pointer;
}
.dropdown-list {
   border-radius: 4px;
   background-color: white;
   position: absolute;
   top: 100%;
   left: 0;
   right: 0;
   opacity: 0;
   visibility: hidden;
   transition: opacity 0.2s linear, visibility 0.2s linear;
   transform: translateY(10px);
}
.dropdown-list:before {
   content: "";
   width: 100%;
   height: 10px;
   background-color: transparent;
   position: absolute;
   top: 0;
   left: 0;
   transform: translateY(-100%);
}
.dropdown-list__item {
   padding: 1rem;
   font-size: 1.4rem;
}

.form-option {
   display: flex;
   padding: var(--Paragraph-Space, 16px);
   align-items: center;
   gap: var(--Radius-10, 10px);
   align-self: stretch;
   border-radius: 5px;
   border: 1px solid rgba(180, 180, 180, 0.3);
   background: #222;
}

.radio-symbol .inner-circle {
   display: none;
}

.r-option input[type="radio"]:checked + .radio-symbol .inner-circle {
   display: block;
}

.form-option input[type="radio"]:checked + .radio-symbol .inner-circle {
   display: block;
}

.r-option input[type="radio"] {
   opacity: 0;
   position: absolute;
   pointer-events: none;
}

.form-option input[type="radio"] {
   opacity: 0;
   position: absolute;
   pointer-events: none;
}

.place-order {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   gap: var(--Radius-10, 10px);
   align-self: stretch;
}

.order-btn {
   display: flex;
   padding: var(--Paragraph-Space, 16px) 30px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   background: var(--Secondary-Color, #ffd600);
}

.orderLabel {
   color: var(--Dark-1, #333);
   font-family: Roboto;
   font-size: 16px;
   font-style: normal;
   font-weight: 700;
   line-height: normal;
}

.extraText {
   color: #fff;
   text-align: center;
   font-family: Roboto;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   align-self: stretch;
   opacity: 0.8;
}

.order-form {
   display: flex;
   padding: var(--Paragraph-Space, 16px);
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 12px;
   align-self: stretch;
   background: #333;
}
.order-input {
   display: flex;
   height: var(--Radius-50, 50px);
   padding: var(--Paragraph-Line-Height, 24px) var(--Paragraph-Space, 16px);
   align-items: center;
   gap: var(--Radius-10, 10px);
   align-self: stretch;
   background: var(--Dark-3, #222);
}

.order-btn1 {
   display: flex;
   padding: var(--Paragraph-Space, 16px) 30px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   background: var(--Secondary-Color, #ffd600);
}

.radio-symbol {
   width: var(--Paragraph-Space, 16px);
   height: var(--Paragraph-Space, 16px);
}

.typeDiv {
   display: flex;
   justify-content: space-between;
   align-items: center;
   align-self: stretch;
}

.curr-div {
   display: flex;
   align-items: center;
   gap: 8px;

   p {
      color: #fff;
      text-align: center;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      line-height: 100%;

      @include flex-center;
      gap: 2px;
   }
}

.curr-option {
   display: flex;
   padding: 6px 8px;
   justify-content: center;
   align-items: center;
   gap: 4px;
   border-radius: 5px;
   background: #000;
   line-height: 100%;
}

.select-test {
   display: flex;
   height: var(--Radius-50, 50px);
   padding: 12px var(--Radius-20, 20px);
   justify-content: space-between;
   align-items: center;
   align-self: stretch;
   border-radius: 5px;
   border: 1px solid rgba(180, 180, 180, 0.3);
}

.option-cost {
   display: flex;
   gap: 8px;
   justify-content: flex-end;
   align-self: stretch;
}

.option-cost-res{
      display: flex;
      gap: 8px;
      justify-content: flex-end;
      align-self: stretch;
}

.option-cost-cost {
   color: #ffd600;
   text-align: center;
   font-family: Roboto;
   font-size: 13px;
   font-style: normal;
   font-weight: 700;
   line-height: normal;
   opacity: 0.9;
}

.option-rectangle {
   width: 5px;
   align-self: stretch;
   opacity: 0.1;
   background: #fff;
}

.option-time {
   color: rgba(#fff, 0.8);
   text-align: center;
   font-family: Roboto;
   font-size: 13px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;

   .strikethrough {
      color: rgba(255, 255, 255, 0.70);
      text-align: center;
      font-family: Roboto;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: line-through;
      opacity: 0.7;
   }

   span {
      font-weight: bold;
      color: #ffd600;
   }
}

.strikethrough {
   color: rgba(255, 255, 255, 0.7);
   text-align: center;
   font-family: Roboto;
   font-size: 13px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   text-decoration-line: line-through;
   opacity: 0.9;
}

.r-type {
   display: flex;
   align-items: flex-start;
   align-content: flex-start;
   gap: 8px;
   align-self: stretch;
   flex-wrap: wrap;
}

.r-option {
   display: flex;
   height: var(--Radius-50, 50px);
   padding: var(--Paragraph-Space, 16px);
   align-items: center;
   gap: var(--Radius-10, 10px);
   border-radius: 5px;
   border: 1px solid rgba(255, 255, 255, 0.2);
}

.r-option:has(input[type="radio"]:checked){
   border: 1px solid rgba(255, 214, 0, 0.8);
   border-radius: 5px;
   padding: var(--Paragraph-Space, 16px);
   display: flex;
   align-items: center;
   gap: var(--Radius-10, 10px);
   height: var(--Radius-50, 50px);
}

.country-options {
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #222;
   padding: 0px;
   border-radius: 5px;
   width: 145px;
   height: 27px;
}

.s-select {
   width: 100%;
   padding: 0 0 0 0 !important;
   margin: 0;
   background: #000;
   border-radius: 3px;
   height: 30px;

   // height: 19px;
   // margin-top: 4px;

   * {
      border-radius: 0;
   }

   button {
      border: none;
      padding: 0;
      height: 30px;
      margin: 0;

      &::after {
         margin-left: 0;
         margin-right: 0.5rem;
      }
   }

   ul {
      background: black;
      border: none;

      div {
         background: #000;

         input {
            border: 1px solid rgba($color: #fff, $alpha: 0.2);
            background: transparent;
            color: #ffd600;
         }
      }

      li:hover {
         background: #222;
      }
   }
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
   cursor: none;
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   /* padding: 5px 10px; */
   font-family: inherit;
   color: #4d4d4d;
   border: thin solid rgba(77, 77, 77, 0.3);
   border-radius: 4px;
   background: transparent;

   height: 19px;
   margin-top: 4px;
   justify-content: space-evenly;
   padding: 0px 0px;
}

.s-select .ReactFlagsSelect-module_selectBtn__1kE5p {
   background-color: #333;
   color: white;
   border: none;
   outline: none;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 2px 10px; 
   border-radius: 5px;
   height: 27px; 
   box-sizing: border-box;
}

.s-select .ReactFlagsSelect-module_selectBtn__1kE5p:hover {
   background-color: #444;
}

.s-select .ReactFlagsSelect-module_selectBtn__1kE5p::after {
   border-color: white transparent transparent transparent;
}

.s-select .ReactFlagsSelect-module_flagSelectOptions__3F2SO {
   background-color: #333;
   color: white;
}

.s-select .ReactFlagsSelect-module_flagSelectOption__1u6d9:hover {
   background-color: #444;
}

.recommend{
   display: flex;

   svg{
      align-items: center;
      margin-top: 2px;
   }

}

.recommended-label1 {
   display: inline-block;
   border-radius: var(--Radius-20, 20px);
   background: #FFD600;
   
   
   display: flex;
   font-size: 14px;
   padding: 2px 8px;
   margin-left: 8px;
   p{
      color: #1B1B1B;
      font-family: Roboto;
      font-weight: 400;
   }
 }  

 .limited-offer{
   display: flex;
   padding: 4px 8px;
   align-items: center;
   gap: 4px;
   border-radius: 15px;
   background: #FFD600;

   p{
      color: #1B1B1B;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
   }
 }

 .responsive-flex{
   display: flex;
   align-items: flex-start;
   align-content: flex-start;
   gap: 8px;
   align-self: stretch;
   flex-wrap: wrap;
 }

 .yes-option{
   display: flex;
   height: var(--Radius-50, 50px);
   padding: var(--Paragraph-Space, 16px);
   justify-content: space-between;
   gap: var(--Radius-10, 10px);
   align-items: center;
   flex: 1 0 0;
   border-radius: 5px;
   border: 1px solid rgba(255, 214, 0, 0.80);
 }

 .yes-option input[type="radio"]:checked + .radio-symbol .inner-circle {
   display: block;
}

.yes-option input[type="radio"] {
   opacity: 0;
   position: absolute;
   pointer-events: none;
}

 .no-option{
   display: flex;
   height: var(--Radius-50, 50px);
   padding: var(--Paragraph-Space, 16px);
   align-items: center;
   gap: var(--Radius-10, 10px);
   border-radius: 5px;
   border: 1px solid rgba(255, 255, 255, 0.05);
   background: rgba(255, 255, 255, 0.05);

   label{
      color: rgba(255, 255, 255, 0.30);
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
   }
 }

 .country-flex{
   display: flex;
   gap: 3px;
 }

 .btn-txt{
   color: var(--Dark-1, #333);
   font-family: Roboto;
   font-size: 16px;
   font-style: normal;
   font-weight: 700;
   line-height: normal;
 }