// -----------------------------------------
// Typography Mixins
// -----------------------------------------

@mixin font-weight($val) {
   font-weight: map-get($map: $font-weight, $key: $val);
}

@mixin dark-color-bg($val) {
   background: map-get($map: $black-colors, $key: $val);
}

@mixin dark-color($val) {
   color: map-get($map: $black-colors, $key: $val);
}

@mixin para-font() {
   font-size: 1rem;
   color: $light;
   opacity: 1;
   @include font-weight(light);
   // letter-spacing: 0.05em;
   line-height: 1.5em;
}

@mixin heading-font() {
   font-size: 2.5rem;
   @include font-weight(bold);
}

// -----------------------------------------
// Flex Mixins
// -----------------------------------------
@mixin flex-center($gap:0) {
   display: flex;
   justify-content: center;
   align-items: center;
   gap: $gap;
}

@mixin flex-between {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

// -----------------------------------------
// SLick Slider Mixins
// -----------------------------------------
@mixin slick-dots {
   .slick-dots {
      position: absolute;
      bottom: -40px !important;

      * {
         cursor: none !important;
      }

      li {
         width: 10px;
         height: 8px;
      }

      li button::before {
         content: '' !important;
         width: 100%;
         height: 100%;
         @include dark-color-bg(light);
         opacity: 1 !important;
      }

      li.slick-active {
         width: 25px !important;
      }

      li.slick-active button::before {
         background: $yellow;
         opacity: 0.5 !important;
      }
   }
}