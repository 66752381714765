// Fonts
$primary-font: "Roboto",
sans-serif;

// Font weights
$font-weight: ("thin" : 100,
   "light": 300,
   "regular": 400,
   "medium": 500,
   "bold": 700,
   "black": 900);

// Colors
$black-colors: ("dark": #222222,
   "medium": #333333,
   "light": #48484880);
$light: #EEEEEE;
$yellow: #FFD600;
$orange: #F2994A;