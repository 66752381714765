.p-thanks {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   text-align: center;
   padding: 2rem;
   border: 1px solid #333;
   background-color: #222;
   width: 100%;
   max-width: 500px;
   margin: 80px auto;
}

.icon-wrapper {
   display: flex;
   align-items: center;
   justify-content: center;
   margin-bottom: 20px;
}

.check-icon {
   background-color: rgba(255, 214, 0, 0.2);
   border-radius: 50%;
   padding: 10px;
   display: flex;
   align-items: center;
   justify-content: center;
}

h2 {
   font-size: 1.5em;
   margin: 0 0 10px;
   color: $yellow;
}

.give_margin {
   display: flex;
   flex-direction: column;
   margin-bottom: 2rem;
   gap: 5px;
}

.back-button {
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #f0f0f0;
   border: none;
   border-radius: 5px;
   padding: 10px 20px;
   margin-top: 20px;
   cursor: pointer;
   color: #333;
   font-size: 1em;
}
